import { useState } from "react";

const container = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "stretch",
	height: "100vh",

	padding: "20px 20px 0 20px",
};
const textarea = {
	width: "100%",
	height: "100%",
	padding: 0
};
const htmldiv = {
	border: "1px solid black",
	height: "100%",
};
function App() {
	const [html, setHtml] = useState("");
	return (
		<div style={container}>
			<div style={{ flexGrow: 1 }}>
				<textarea
					style={textarea}
					value={html}
					onChange={(e) => setHtml(e.target.value)}
				/>
			</div>
			<div style={{ flexGrow: 1 }}>
				<div
					style={htmldiv}
					dangerouslySetInnerHTML={{ __html: html }}
				/>
			</div>
		</div>
	);
}

export default App;
